<template>
    <h4
        class="text-md font-bold uppercase tracking-widest text-plu-blue md:text-2xl"
    >
        <slot></slot>
    </h4>
</template>
<style scoped>
:deep(span) {
    @apply text-plu-blue;
}
</style>
<script setup></script>
